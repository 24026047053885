/**
 * Created by rsierra on 28/09/2017.
 */

import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'angular-bootstrap-md';

@Component({

  selector: 'app-modal-confirm',
  templateUrl: 'modal-confirm.component.html'
})
export class ModalConfirmComponent {
  @ViewChild('confirmModal')
  modal: ModalDirective;
  @Output('resolveAction')
  resolveAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  mainText: string;
  @Input()
  okText = 'Aceptar';
  @Input()
  cancelText = 'Cancelar';

  accept() {
    this.resolveAction.emit(true);
    this.modal.hide();
  }

  cancel() {
    this.resolveAction.emit(false);
    this.modal.hide();
  }

}
