
const baseUrl = 'https://www.corregidoraenlinea.gob.mx'
const basePort = '9990'

export const environment = {
  production: true,
  apiBaseUrl: baseUrl + ':'+basePort+'',
  apiBaseUrlMc: baseUrl + '',
  multipagosURL: 'https://www.adquiramexico.com.mx/clb/endpoint/corregidora',
  mp_urlsuccess: baseUrl + ':'+basePort+'/api/payments/na/success',
  mp_urlfailure: baseUrl + ':'+basePort+'/api/payments/na/fail',
  pagos_config: {
    bancos: [
      {
        active: true,
        nombre: 'bbva',
        url: 'https://www.adquiramexico.com.mx/clb/endpoint/corregidora',
        opciones: {
          mp_acount: 327
        }
      }
    ],
    endpoints: [
      {key: 'general', endpoint: baseUrl + ':'+basePort+'/api/payments/na/success', tipo:'success'},
      {key: 'general', endpoint: baseUrl + ':'+basePort+'/api/payments/na/fail', tipo:'failure'},
      {key: 'dummy', endpoint: baseUrl + ':'+basePort+'/api/pagos/na/pago-dummy', tipo:'success'},
      {key: 'dummy', endpoint: baseUrl + ':'+basePort+'/api/pagos/na/pago-dummy', tipo:'failure'},
      {key: 'venunica', endpoint: baseUrl + ':'+basePort+'/api/pagos/na/pago', tipo:'success'},
      {key: 'venunica', endpoint: baseUrl + ':'+basePort+'/api/pagos/na/pago', tipo:'failure'},
    ]
  },
  multipagosURL_mc: 'https://www.adquiramexico.com.mx/clb/endpoint/corregidora',
  mp_urlsuccess_mc: baseUrl + ':'+basePort+'/api/payments/na/success',
  mp_urlfailure_mc: baseUrl + ':'+basePort+'/api/payments/na/fail',
  mp_acount: 327,
  key: 'McuOlrtriepgadgEoqsRBoa2nxc0o1m1eDr543EFteuweh',
  dbLocalName: 'localDB',
  dbLocalVersion: 1,
  portal_url: baseUrl + '/#',
  MerchantID: "8739923",
  User: "SING2022",
  Password: "IngreSOS?0?2",
  Mode: "AUT",
  TerminalID: "87399231",
  MerchantName: "MUN CAMPECHE RECAUD",
  MerchantCity: "SFC",
  Language: "ES",
  VCE:"1",
  CMD_TRANS:"VERIFY",
  lastFolTD:1339810
};

